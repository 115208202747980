import React from 'react';
import News from '@/Widgets/News Card/news';

function Test({ elAll, inputRef, State }: any) {
	return <News elAll={elAll} inputRef={inputRef} State={State} />;
}

export const ChildComponent = React.memo(Test);

export const ValidateUrl = () => {
	let Url = window.location.href;
	let changeUrl = Url.split('/');

	return !(changeUrl[2] === 'smi2.ru');
};
