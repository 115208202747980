import style from '@/Features/NewsComponents/news.module.sass';
import { useRef, useState, useEffect, RefObject } from 'react';
import Image from 'next/image';

interface NewsCardMTCountersProps {
	StoryId: number;
}

export function NewsCardMTCounters({ StoryId }: NewsCardMTCountersProps) {
	const ArticlesStoryId = StoryId;

	const elComments: RefObject<HTMLDivElement> = useRef(null);
	const elLikes: RefObject<HTMLDivElement> = useRef(null);
	const elDislikes: RefObject<HTMLDivElement> = useRef(null);
	const elUserReaction: RefObject<HTMLDivElement> = useRef(null);

	const [likes, setLikes] = useState<number>(0);
	const [comments, setComments] = useState<number>(0);

	const id = ArticlesStoryId !== undefined ? `story-${ArticlesStoryId}` : false;
	const type = 'smi2';

	useEffect(() => {
		if (id) {
			window.mtMessenger.push([
				'subscribeChatExternalMessageCount',
				{
					id,
					type,
					callbackFn: (value: string) => {
						if (elComments.current) {
							elComments.current.innerText = value;
						}
						setComments(parseInt(value, 10));
					}
				}
			]);

			window.mtMessenger.push([
				'subscribeChatExternalReactions',
				{
					id,
					type,
					callbackFn: ({
						reactions: { like, dislike },
						userReaction
					}: {
						reactions: { like: string; dislike: string };
						userReaction: string;
					}) => {
						if (elLikes.current) {
							elLikes.current.innerText = like;
						}
						if (elDislikes.current) {
							elDislikes.current.innerText = dislike;
						}
						if (elUserReaction.current) {
							elUserReaction.current.innerText = userReaction;
						}
						setLikes(parseInt(like, 10));
					}
				}
			]);
		}
	}, [id, type]);

	return (
		<span className={style.CountersWrapper}>
			{likes > 0 && (
				<>
					<Image width={8} height={20} src='/hot.svg' alt='hotNews' />
					<div ref={elLikes} className={style.hotNewsCounter}>
						{likes}
					</div>
				</>
			)}
			{comments > 0 && (
				<>
					<Image width={12} height={20} src='/Comment.svg' alt='Comments' />
					<div ref={elComments} className={style.commentCounter}>
						{comments}
					</div>
				</>
			)}
		</span>
	);
}
