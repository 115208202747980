'use client';

import { NewsCardMTCounters } from '@/Features/MT/Counters';
import style from './news.module.sass';
import { useState, useEffect, useRef } from 'react';
import { useAppSelector } from '@/Hooks/redux';
import Image from 'next/image';
import Link from 'next/link';
import { NewsCardMTButtons } from '@/Features/MT/Buttons';
import { NewsCardMTButtonsCard } from '@/Features/MT/ButtonsCard';
import NewsText from './TextRedactor';
import { ArticlesMTCounters } from '@/Features/MT/StoryIDCounters';
import { OpenTeaser } from '@/Hooks/useTiser';
import { SimilarMTButtons } from '../MT/StoryIDBtns';

export const StoryItem = ({ el, inputRef }: { el: any; inputRef: any }) => {
	const { newsViewBollean } = useAppSelector(state => state.NewsViewSlice);
	const { CurrentFontSize } = useAppSelector(state => state.HeaderState);
	const [isHovered, setHovered] = useState({ isHover: false, id: NaN });
	const [isMobile, setIsMobile] = useState(false);
	const [isStories, setIsStories] = useState(true);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		// Check on mount
		handleResize();

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleOptionsClick = () => {
		setHovered(prev =>
			prev.isHover && prev.id === el.id
				? { isHover: false, id: NaN }
				: { isHover: true, id: el.id }
		);
	};

	const handleMouseEnter = () => {
		if (!isMobile) {
			setHovered({ isHover: true, id: el.id });
		}
	};

	const handleMouseLeave = () => {
		if (!isMobile) {
			setHovered({ isHover: false, id: el.id });
		}
	};
	// Функция для прокрутки до элемента
	const scrollToElement = () => {
		if (elContainer.current) {
			elContainer.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	// Время статьи
	const Todaydate = new Date();
	const date = new Date(el?.date);
	let ValidationDate = () => {
		if (el?.date !== undefined) {
			if (formatterDate.format(date) !== formatterDate.format(Todaydate)) {
				return `,  ${formatterDate.format(date)}`;
			} else {
				return <></>;
			}
		}
	};
	let hours = date.getHours();
	const formatterDate = new Intl.DateTimeFormat('ru', {
		month: 'long',
		day: 'numeric'
	});
	let minutes = () => {
		if (date.getMinutes() < 10) {
			return '0' + date.getMinutes();
		} else {
			return date.getMinutes();
		}
	};

	const elContainer = useRef<any>(null);
	return (
		<>
			{newsViewBollean == false ? (
				<section
					key={el.id}
					className={style.Wrapper}
					ref={inputRef}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<div className={style.cardFlexWrap}>
						<div className={style.logoNameCont}>
							<Link
								href={`/publisher/${el.publisher_id}`}
								className={style.logoNameCont_Logo}
							>
								<Image
									width={16}
									height={16}
									src={el.publisher_logo_url}
									alt='logo'
								/>
							</Link>
							<a
								className={style.Links}
								// target={window.ReactNativeWebView ? '_self' : '_blank'}
								target='_blank'
								href={`/article/${el.id}/story?newApi=1`}
							>
								<h3
									className={style.mainTxt}
									style={{
										fontSize: `${16 + CurrentFontSize}px`
									}}
								>
									<NewsText
										StoryId={el.story_id}
										text={el.title}
										url={el.url}
										Id={el.id}
										PublisherId={el.publisher_id}
										el={el}
									/>
								</h3>
							</a>
						</div>
						<div className={style.ContofOptions}>
							{/* <div
								className={
									isHovered.isHover === true && isHovered.id === el.id
										? style.optionsCont
										: style.optionsContClose
								}
							>
								<a
									className={style.LinkComment}
									target={window.ReactNativeWebView ? '_self' : '_blank'}
									onClick={e => {
										OpenTeaser(el);
										if (window.ReactNativeWebView) {
											e.preventDefault();
										}
									}}
									href={`/article/${el.id}/story#container`}
								>
									<div className={style.commentBtn}>
										<svg
											width='15'
											height='15'
											viewBox='0 0 12 11'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												fill-rule='evenodd'
												clip-rule='evenodd'
												d='M10.3217 9.38843C8.57026 11.0695 5.97684 11.4327 3.85454 10.4907C3.54123 10.3696 3.28437 10.2718 3.04017 10.2718C2.36 10.2757 1.99268 11 0.555679 10.989C-1.03436 10.9768 1.29338 9.625 1.29338 8.59461C1.29338 8.36022 1.19547 8.11809 1.06934 7.81681C0.0873792 5.78029 0.466341 3.29048 2.21774 1.60996C4.45351 -0.536563 8.0859 -0.536562 10.3217 1.60941C12.5615 3.75925 12.5574 7.24245 10.3217 9.38843Z'
												fill='#808080'
											/>
										</svg>
										Обсудить
									</div>
								</a>
							</div> */}
							<div className={style.HotBtnCont}>
								<NewsCardMTButtons
									StoryId={el.story_id}
									isHovered={
										isHovered.isHover === true && isHovered.id === el.id
									}
								/>
							</div>
						</div>
					</div>
					<a
						className={
							isHovered.isHover === true && isHovered.id === el.id
								? style.openOptionsCont
								: style.openOptionsContFalse
						}
						// className={style.openOptionsContFalse}
						// target='_blank'
						// href={
						// 	el.hasOwnProperty('fulltext_properties')
						// 		? `/article/${el.id}/full`
						// 		: el.url
						// }
						onClick={handleOptionsClick}
					>
						<svg
							width='16'
							height='16'
							viewBox='0 0 16 4'
							fill='#858585'
							xmlns='http://www.w3.org/2000/svg'
						>
							<circle cx='1.75' cy='1.75' r='1.75'></circle>
							<circle cx='7.875' cy='1.75' r='1.75'></circle>
							<circle cx='14' cy='1.75' r='1.75'></circle>
						</svg>
						{/* <svg
							width='17'
							height='17'
							viewBox='0 0 17 17'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M10 1H16M16 1V7M16 1L8 9'
								stroke='#858585'
								stroke-width='2'
							/>
							<path
								d='M7 2H3C1.89543 2 1 2.89543 1 4V14C1 15.1046 1.89543 16 3 16H13C14.1046 16 15 15.1046 15 14V10'
								stroke='#858585'
								stroke-width='2'
								stroke-linecap='round'
							/>
						</svg> */}
					</a>
				</section>
			) : (
				<section ref={inputRef} key={el.id} className={style.CardWrapper}>
					{el.image == null ? (
						<div className={style.NewsPreview}></div>
					) : (
						// <Image
						// 	className={style.NewsPreview}
						// 	src={el.image}
						// 	alt='logo'
						// 	width={280}
						// 	height={200}
						// 	display
						// />
						<Image
							className={style.NewsPreview}
							src={el.image}
							alt='logo'
							width={280}
							height={200}
						/>
					)}

					<div className={style.NewsCardName}>
						<Image
							width={16}
							height={16}
							src={el.publisher_logo_url}
							alt='logo'
						/>
						<h4
							className={style.NewsName}
							style={{
								fontSize: `${14 + CurrentFontSize}px`
							}}
						>
							{el.publisher_name}
						</h4>
						<ArticlesMTCounters StoryId={el.story_id} />
					</div>
					<div className={style.logoNameContCard}>
						<h3
							className={style.mainCardTxt}
							style={{
								fontSize: `${19 + CurrentFontSize}px`
							}}
						>
							<a
								className={style.Links}
								target={window.ReactNativeWebView ? '_self' : '_blank'}
								href={`/article/${el.id}/story?newApi=1`}
							>
								{el.title}
							</a>
						</h3>
					</div>
					<NewsCardMTButtonsCard
						el={el}
						StoryId={el.story_id}
						Id={el.id}
						isStories={isStories}
					/>
				</section>
			)}
		</>
	);

	// return (
	// 	<>
	// 		<section
	// 			key={el.id}
	// 			ref={inputRef}
	// 			onMouseEnter={handleMouseEnter}
	// 			onMouseLeave={handleMouseLeave}
	// 		>
	// 			<div className={style.mainWrapper}>
	// 				<div className={style.CONTOFCONT}>
	// 					<section className={style.CardWrapper} key={el.id}>
	// 						<div className={style.NewsCardName}>
	// 							{el.publisher_logo_url !== undefined ? (
	// 								<Image
	// 									width={16}
	// 									height={16}
	// 									src={el.publisher_logo_url}
	// 									alt='logo'
	// 								/>
	// 							) : (
	// 								<div className={style.LazyLoading}></div>
	// 							)}
	// 							<div className={style.newsMetrics}>
	// 								<span className={style.Time}>
	// 									{!Number.isNaN(hours) ? `${hours} : ${minutes()}` : ''}
	// 									<span className={style.Date}>{ValidationDate()}</span>
	// 								</span>
	// 								<ArticlesMTCounters StoryId={el.story_id} />
	// 								{/* <span className={style.Time}>
	// 									<svg
	// 										width='20'
	// 										height='20'
	// 										viewBox='0 0 24 24'
	// 										fill='none'
	// 										xmlns='http://www.w3.org/2000/svg'
	// 									>
	// 										<path
	// 											d='M12 5C6.55576 5 3.53109 9.23425 2.45554 11.1164C2.23488 11.5025 2.12456 11.6956 2.1367 11.9836C2.14885 12.2716 2.27857 12.4598 2.53799 12.8362C3.8182 14.6935 7.29389 19 12 19C16.7061 19 20.1818 14.6935 21.462 12.8362C21.7214 12.4598 21.8511 12.2716 21.8633 11.9836C21.8754 11.6956 21.7651 11.5025 21.5445 11.1164C20.4689 9.23425 17.4442 5 12 5Z'
	// 											stroke='#808080'
	// 											stroke-width='2'
	// 										/>
	// 										<circle cx='12' cy='12' r='4' fill='#808080' />
	// 									</svg>
	// 									<span>46892</span>
	// 								</span> */}
	// 							</div>
	// 						</div>

	// 						<h3
	// 							className={style.mainTitleTxt}
	// 							style={{
	// 								fontSize: `${16 + CurrentFontSize}px`
	// 							}}
	// 						>
	// 							<a href={`/article/${el.id}/story?newApi=1`}>{el.title}</a>
	// 						</h3>

	// 						<section className={style.ImageText}>
	// 							{el.url && (
	// 								<a
	// 									href={`/article/${el.id}/story?newApi=1`}
	// 									style={{
	// 										textDecoration: 'none'
	// 									}}
	// 								>
	// 									<div className={style.logoNameCont}>
	// 										{/* <StoryTextRedactor el={el} /> */}
	// 										<p
	// 											className={style.mainCardTxt}
	// 											style={{
	// 												fontSize: `${15 + CurrentFontSize}px`
	// 											}}
	// 										>
	// 											{el.image !== null &&
	// 												(el.image !== undefined ? (
	// 													<Image
	// 														className={style.NewsPreview}
	// 														src={el.image}
	// 														alt='logo'
	// 														width={247}
	// 														height={156}
	// 														style={{ float: 'left', marginRight: '15px' }}
	// 													/>
	// 												) : (
	// 													<></>
	// 												))}

	// 											<div>{el.announce}</div>
	// 										</p>
	// 									</div>
	// 								</a>
	// 							)}
	// 						</section>
	// 						<div className={style.optionsCardCont}>
	// 							{/* <button className={style.BtnReadNext}>
	// 								{el.url && (
	// 									<a
	// 										href={
	// 											el.hasOwnProperty('fulltext_properties')
	// 												? `/article/${el.id}/full`
	// 												: el.url
	// 										}
	// 										target='_blank'
	// 										style={{
	// 											color: 'white',
	// 											textDecoration: 'none',
	// 											fontWeight: '700'
	// 										}}
	// 									>
	// 										Читать <span className={style.commentBtnMob}>далее</span>
	// 									</a>
	// 								)}
	// 							</button> */}
	// 							<div className={style.actionBtns}>
	// 								{/* <div> */}
	// 								{/* <div className={style.shareBtn} onClick={OpenFunc}>
	// 										<svg
	// 											width='20'
	// 											height='20'
	// 											viewBox='0 0 24 24'
	// 											xmlns='http://www.w3.org/2000/svg'
	// 											style={isOpen ? { fill: '#11bb61' } : {}}
	// 										>
	// 											<path d='M18,16.08C17.24,16.08 16.56,16.38 16.04,16.85L8.91,12.7C8.96,12.47 9,12.24 9,12C9,11.76 8.96,11.53 8.91,11.3L15.96,7.19C16.5,7.69 17.21,8 18,8A3,3 0 0,0 21,5A3,3 0 0,0 18,2A3,3 0 0,0 15,5C15,5.24 15.04,5.47 15.09,5.7L8.04,9.81C7.5,9.31 6.79,9 6,9A3,3 0 0,0 3,12A3,3 0 0,0 6,15C6.79,15 7.5,14.69 8.04,14.19L15.16,18.34C15.11,18.55 15.08,18.77 15.08,19C15.08,20.61 16.39,21.91 18,21.91C19.61,21.91 20.92,20.61 20.92,19A2.92,2.92 0 0,0 18,16.08Z'></path>
	// 										</svg>
	// 									</div> */}

	// 								{/* {isOpen && !resultUserAgent ? (
	// 										<ShareModal
	// 											isOpen={isOpen}
	// 											setOpen={setOpen}
	// 											setCopied={setCopied}
	// 											copied={copied}
	// 										/>
	// 									) : (
	// 										<></>
	// 									)} */}
	// 								{/* </div> */}

	// 								<div className={style.commentBtn}>
	// 									<a href={`/article/${el.id}/story?newApi=1`}>
	// 										<svg
	// 											width='18'
	// 											height='18'
	// 											viewBox='0 0 26 26'
	// 											fill='none'
	// 											xmlns='http://www.w3.org/2000/svg'
	// 										>
	// 											<path
	// 												d='M13.0036 13.4657C12.7464 13.4657 12.5378 13.2571 12.5378 12.9998C12.5378 12.7425 12.7464 12.5339 13.0036 12.5339'
	// 												stroke='$BackgroundBtnMT-color'
	// 												stroke-width='2'
	// 												stroke-linecap='round'
	// 												stroke-linejoin='round'
	// 											/>
	// 											<path
	// 												d='M13.0035 13.4657C13.2608 13.4657 13.4693 13.2571 13.4693 12.9998C13.4693 12.7425 13.2608 12.5339 13.0035 12.5339'
	// 												stroke='$BackgroundBtnMT-color'
	// 												stroke-width='2'
	// 												stroke-linecap='round'
	// 												stroke-linejoin='round'
	// 											/>
	// 											<path
	// 												d='M7.88047 13.4657C7.62325 13.4657 7.41473 13.2571 7.41473 12.9998C7.41473 12.7425 7.62325 12.5339 7.88047 12.5339'
	// 												stroke='$BackgroundBtnMT-color'
	// 												stroke-width='2'
	// 												stroke-linecap='round'
	// 												stroke-linejoin='round'
	// 											/>
	// 											<path
	// 												d='M7.88049 13.4657C8.13771 13.4657 8.34623 13.2571 8.34623 12.9998C8.34623 12.7425 8.13771 12.5339 7.88049 12.5339'
	// 												stroke='$BackgroundBtnMT-color'
	// 												stroke-width='2'
	// 												stroke-linecap='round'
	// 												stroke-linejoin='round'
	// 											/>
	// 											<path
	// 												d='M18.1266 13.4657C17.8694 13.4657 17.6609 13.2571 17.6609 12.9998C17.6609 12.7425 17.8694 12.5339 18.1266 12.5339'
	// 												stroke='$BackgroundBtnMT-color'
	// 												stroke-width='2'
	// 												stroke-linecap='round'
	// 												stroke-linejoin='round'
	// 											/>
	// 											<path
	// 												d='M18.1266 13.4657C18.3838 13.4657 18.5924 13.2571 18.5924 12.9998C18.5924 12.7425 18.3838 12.5339 18.1266 12.5339'
	// 												stroke='$BackgroundBtnMT-color'
	// 												stroke-width='2'
	// 												stroke-linecap='round'
	// 												stroke-linejoin='round'
	// 											/>
	// 											<path
	// 												d='M12.9965 1C10.8265 1.00068 8.6973 1.5901 6.83571 2.7055C4.97413 3.8209 3.44986 5.42049 2.42525 7.33391C1.40063 9.24732 0.914052 11.4029 1.01733 13.5711C1.1206 15.7392 1.80987 17.8388 3.01172 19.646L1 25L7.73647 23.7815C9.3586 24.5744 11.1387 24.9909 12.9441 24.9998C14.7496 25.0088 16.5337 24.61 18.1636 23.8331C19.7935 23.0564 21.2272 21.9215 22.3574 20.5133C23.4878 19.1053 24.2861 17.46 24.6922 15.7003C25.0985 13.9407 25.1026 12.112 24.704 10.3506C24.3053 8.5892 23.5145 6.94051 22.3902 5.52743C21.2659 4.11434 19.8372 2.97333 18.2108 2.18943C16.5843 1.40551 14.8019 0.998936 12.9965 1Z'
	// 												stroke='$BackgroundBtnMT-color'
	// 												stroke-width='2'
	// 												stroke-linecap='round'
	// 												stroke-linejoin='round'
	// 											/>
	// 										</svg>
	// 									</a>
	// 								</div>

	// 								<SimilarMTButtons StoryId={el.story_id} />
	// 							</div>
	// 						</div>
	// 					</section>
	// 				</div>
	// 			</div>
	// 		</section>
	// 	</>
	// );
};
