import React, { useCallback } from 'react';
import style from './news.module.sass';
import Link from 'next/link';
import { NewsCardMTCounters } from '@/Features/MT/Counters';
import { OpenTeaser } from '@/Hooks/useTiser';

interface NewsTextProps {
	StoryId: number;
	text: string;
	url: string;
	Id: number;
	PublisherId: number;
	el: any;
}

const NewsText: React.FC<NewsTextProps> = ({
	StoryId,
	text,
	url,
	Id,
	PublisherId,
	el
}) => {
	const wrapLastWordInSpan = useCallback(
		(text: string) => {
			const words = text.split(' ');
			if (words.length > 0) {
				const lastWord = words.pop();
				return (
					<>
						{words.join(' ')}{' '}
						<span className={style.lastWord}>
							{lastWord}
							<NewsCardMTCounters StoryId={StoryId} />
						</span>
					</>
				);
			}
			return text;
		},
		[StoryId]
	);

	return (
		// <a
		// 	className={style.Links}
		// 	// target={window.ReactNativeWebView ? '_self' : '_blank'}
		// 	target='_blank'
		// 	href={
		// 		el.hasOwnProperty('fulltext_properties')
		// 			? `/article/${el.id}/full`
		// 			: el.url
		// 	}
		// >
			<div className={style.NewsTextWrapper}>
				<div className={style.TxtNews}>{wrapLastWordInSpan(text)}</div>
			</div>
		// </a>
	);
};

export default React.memo(NewsText);
