import Tooltip from '@/lib/ToolTip';
import style from '@/Features/NewsComponents/news.module.sass';
import React, { useRef, useState, useEffect } from 'react';

interface NewsCardMTButtonsProps {
	StoryId: number;
	isHovered: any;
	boxColor?: any;
}

interface HoverState {
	isHover: boolean;
	id: number;
}

interface ReactionData {
	like: number;
	dislike: number;
	userReaction: 'like' | 'dislike' | null;
}

export function NewsCardMTButtons({
	StoryId,
	isHovered,
	boxColor
}: NewsCardMTButtonsProps) {
	const ArticlesStoryId = StoryId;
	const id = ArticlesStoryId !== undefined ? `story-${ArticlesStoryId}` : false;
	const type = 'smi2';

	const likeRef = useRef<boolean | null>(null);
	const likesCountRef = useRef<number>(0);
	const dislikesCountRef = useRef<number>(0);
	const [renderTrigger, setRenderTrigger] = useState<number>(0);

	useEffect(() => {
		const callbackFn = ({
			reactions: { like, dislike },
			userReaction
		}: {
			reactions: { like: number; dislike: number };
			userReaction: 'like' | 'dislike' | null;
		}) => {
			likesCountRef.current = like;
			dislikesCountRef.current = dislike;
			likeRef.current = userReaction === 'like';
			setRenderTrigger(rt => rt + 1);
		};

		if (id) {
			window.mtMessenger.push([
				'subscribeChatExternalReactions',
				{
					id,
					type,
					callbackFn
				}
			]);
		}

		return () => {
			window.mtMessenger.push([
				'unsubscribeChatExternalReactions',
				{ id, type }
			]);
		};
	}, [id, type]);

	const handleLikeClick = () => {
		const reactionName = likeRef.current ? null : 'like';
		window.mtMessenger.push([
			'setChatExternalReaction',
			{ id, type, reactionName }
		]);
	};

	return (
		// <Tooltip content='Горячо' className={style.TooltipHot}>
		<div
			className={style.hotNewsBtn}
			onClick={handleLikeClick}
			style={
				isHovered || likeRef.current
					? {
							background: '$OptionsButton-color',
							display: 'flex',
							boxShadow: `-11px 0px 0 5px ${boxColor}`
					  }
					: { display: 'none' }
			}
		>
			<svg
				className={likeRef.current ? style.GreenBtn : style.DefaultBtn}
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill={likeRef.current ? '#45AD4E' : 'none'}
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M13 4H8L6 10H9L7 17L14 8H10L13 4Z'
					stroke-width='2'
					stroke-linejoin='round'
				></path>
			</svg>
			<span
				className={
					likeRef.current ? style.HotBtnMobileActive : style.HotBtnMobile
				}
			>
				Горячо
			</span>
		</div>
		// </Tooltip>
	);
}
