import style from '@/Features/NewsComponents/news.module.sass';
import React, { useRef, useState, useEffect } from 'react';
import Link from 'next/link';

interface NewsCardMTButtonsCardProps {
	StoryId: number;
	Id: number;
	boxColor?: any;
	el: any;
	isStories: any;
}

interface ReactionData {
	like: number;
	dislike: number;
	userReaction: 'like' | 'dislike' | null;
}

export function NewsCardMTButtonsCard({
	StoryId,
	Id,
	boxColor,
	el,
	isStories
}: NewsCardMTButtonsCardProps) {
	const ArticlesStoryId = StoryId;

	const id = ArticlesStoryId !== undefined ? `story-${ArticlesStoryId}` : false;
	const type = 'smi2';

	const likeRef = useRef<boolean | null>(null);
	const likesCountRef = useRef<number>(0);
	const dislikesCountRef = useRef<number>(0);
	const [renderTrigger, setRenderTrigger] = useState<number>(0);

	useEffect(() => {
		const callbackFn = ({
			reactions: { like, dislike },
			userReaction
		}: {
			reactions: { like: number; dislike: number };
			userReaction: 'like' | 'dislike' | null;
		}) => {
			likesCountRef.current = like;
			dislikesCountRef.current = dislike;
			likeRef.current = userReaction === 'like';
			setRenderTrigger(rt => rt + 1);
		};
		if (id) {
			window.mtMessenger.push([
				'subscribeChatExternalReactions',
				{
					id,
					type,
					callbackFn
				}
			]);
		}

		return () => {
			window.mtMessenger.push([
				'unsubscribeChatExternalReactions',
				{ id, type }
			]);
		};
	}, [id, type]);

	const handleLikeClick = () => {
		const reactionName = likeRef.current ? null : 'like';
		window.mtMessenger.push([
			'setChatExternalReaction',
			{ id, type, reactionName }
		]);
	};

	return (
		<div className={style.optionsCardCont}>
			<a
				className={style.Links}
				target={window.ReactNativeWebView ? '_self' : '_blank'}
				href={
					isStories
						? `/article/${el.id}/story?newApi=1`
						: `/article/${el.id}/story`
				}
				style={isStories ? { display: 'none' } : {}}
			>
				<div className={style.commentBtnCard}>
					<svg
						width='15'
						height='15'
						viewBox='0 0 12 11'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							fill-rule='evenodd'
							clip-rule='evenodd'
							d='M10.3217 9.38843C8.57026 11.0695 5.97684 11.4327 3.85454 10.4907C3.54123 10.3696 3.28437 10.2718 3.04017 10.2718C2.36 10.2757 1.99268 11 0.555679 10.989C-1.03436 10.9768 1.29338 9.625 1.29338 8.59461C1.29338 8.36022 1.19547 8.11809 1.06934 7.81681C0.0873792 5.78029 0.466341 3.29048 2.21774 1.60996C4.45351 -0.536563 8.0859 -0.536562 10.3217 1.60941C12.5615 3.75925 12.5574 7.24245 10.3217 9.38843Z'
							fill='#808080'
						/>
					</svg>
					Обсудить
				</div>
			</a>
			<div
				className={style.hotNewsBtnCard}
				onClick={handleLikeClick}
				style={likeRef.current ? { background: '$OptionsButton-color' } : {}}
			>
				<svg
					className={likeRef.current ? style.GreenBtn : style.DefaultBtn}
					width='20'
					height='20'
					viewBox='0 0 20 20'
					fill={likeRef.current ? '#45AD4E' : ''}
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M13 4H8L6 10H9L7 17L14 8H10L13 4Z'
						stroke-width='2'
						stroke-linejoin='round'
					></path>
				</svg>
			</div>
		</div>
	);
}
